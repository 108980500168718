/**
 * @typedef {Object} LatLong
 * @property {Number} lat
 * @property {Number} lng
 */

/**
 * @param {LatLong} latLong - https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglat
 * @param {[Number, Number]} coords - https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatlike [longitude, latitude]
 * @returns boolean
 */
export function isSameCoords(latLong, coords) {
  return (
    isWithinHundredThousandths(latLong.lat, coords[1]) &&
    isWithinHundredThousandths(latLong.lng, coords[0])
  )
}

/**
 * @param {Number} value1
 * @param {Number} value2
 * @returns boolean
 */
function isWithinHundredThousandths(value1, value2) {
  return Math.abs(Math.fround(value1) - value2) * 100_000 < 1
}
