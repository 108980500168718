export function contentFromRelease(release) {
  return {
    category: `Press`,
    date: release.releaseDate,
    title: release.title,
    url: `/press/${release.slug}`,
  }
}

export function sortByReleaseDate(a, b) {
  return dateFromReleaseDate(b.date) - dateFromReleaseDate(a.date)
}

function dateFromReleaseDate(dateString) {
  const parts = dateString.split(`-`)
  return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]) - 1)
}
