import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"

import Spacer from "../spacer"
import Row from "../grid/row"
import Col from "../grid/column"
import { GrayH2Heading } from "../common/heading"

const Section = styled.section`
  background: ${(props) => props.theme.colorSand};

  @media ${(props) => props.theme.smallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const StyledImg = styled.img`
  width: ${rem(72)};
  margin: 0 auto;
  margin-bottom: ${rem(10)};
`

const BlobContent = styled.div.attrs({ className: `styled-p` })`
  margin-top: ${rem(20)};
  width: ${rem(250)};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`

const Number = styled.span`
  opacity: 0.4;
`

// const TextTop = styled.div`
//   @media ${props => props.theme.smallDown} {
//     padding-left: ${rem(20)};
//     padding-right: ${rem(20)};
//   }
// `

const StyledCol = styled(Col)`
  text-align: center;

  @media ${(props) => props.theme.smallDown} {
    margin-bottom: ${rem(40)};
  }
`

const WhatWeDo = (props) => (
  <Section>
    <Container>
      <Spacer top>
        <GrayH2Heading dangerouslySetInnerHTML={{ __html: props.title }} />
      </Spacer>
      <Spacer top bottom>
        <Row>
          <Col smallUp={6} smallUpOffset={6}>
            <p className="styled-h4">{props.description}</p>
          </Col>
        </Row>
      </Spacer>

      <Spacer bottom>
        <Row>
          {props.blobs.map((item, index) => (
            <StyledCol key={index} smallDown={12} smallUp={3}>
              <StyledImg width={72} src={item.icon} />

              <div className="styled-label">
                <Number>0{index + 1}</Number>
                <br />
                {item.title}
              </div>
              <BlobContent dangerouslySetInnerHTML={{ __html: item.content }} />
            </StyledCol>
          ))}
        </Row>
      </Spacer>
    </Container>
  </Section>
)

WhatWeDo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  blobs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
}

export default WhatWeDo
