import * as React from "react"
// eslint-disable-next-line prettier/prettier -- webpack ignore
import '!mapbox-gl';
import MapGL, { Marker } from "react-map-gl"
import MultiTouch from "mapbox-gl-multitouch"
import styled, { useTheme } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import LocationSvg from "../svg/location"
import PlusSvg from "../svg/plus"
import MinusSvg from "../svg/minus"
import { isSameCoords } from "../../utils/is-same-coords"
import { isNumericTuple } from "../../utils/is-numeric-tuple"

const Container = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 90%;
  position: relative;

  .mapboxgl-ctrl {
    opacity: 0.6;

    &.mapboxgl-ctrl-attrib {
      background-color: transparent;
      text-shadow: 0 0 2px ${(props) => props.theme.colorBlack};

      a {
        color: ${(props) => props.theme.colorWhite};
      }
    }

    .mapboxgl-ctrl-logo {
      width: 70px;
      height: 20px;
      background-size: contain;
    }
  }

  .mapbox-improve-map,
  .mapboxgl-control-container {
    display: none;
  }
`

const ZoomControl = styled.div`
  position: absolute;
  z-index: 10;
  bottom: ${rem(10)};
  right: ${rem(10)};
  background-color: ${(props) => props.theme.colorSand};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  button {
    width: ${rem(40)};
    height: ${rem(40)};
    padding: ${rem(10)};
    display: block;

    &:hover {
      color: ${(props) => props.theme.colorWhite};
      background-color: ${(props) => props.theme.colorBlack};
    }

    &:active {
      opacity: 0.8;
    }

    &:first-child {
      position: relative;

      &::after {
        content: "";
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 10%;
        right: 10%;
        background-color: currentColor;
        opacity: 0.1;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }
`

const markerStyles = (theme, size = 30) => ({
  width: rem(size),
  height: rem(size),
  cursor: `pointer`,
  color: theme.colorBlack,
  position: `absolute`,
  top: 0,
  left: 0,
  willChange: `transform`,
})

const mapStyles = () => ({
  width: `100%`,
  height: `100%`,
  position: `absolute`,
  zIndex: 1,
  top: 0,
  left: 0,
  overflow: `hidden`,
})

const ACCESS_TOKEN = `pk.eyJ1IjoiY2xpY2stdGhlcmFwZXV0aWNzIiwiYSI6ImNrN2V1cnB2djBuOWozbm80eHVmNGJxdm8ifQ.t_abdWOqrU-srvfk8mFsbA`

const usBounds = [
  [-124.785, 24.446667], // SW
  [-66.949778, 49.384472], // NE
]

const usCenter = [-95.867389, 37.96258551470642]

const initialZoom = 2.458155593477786

const initialViewState = {
  bounds: usBounds,
  dragRotate: false,
  fitBoundsOptions: { linear: true },
  zoom: initialZoom,
  latitude: usCenter[1],
  longitute: usCenter[0],
  touchZoomRotate: false,
}

const Map = (props) => {
  const mapRef = React.useRef(null)
  const theme = useTheme()

  /* c8 ignore next 29 */
  const handleLoad = () => {
    mapRef.current?.addControl(new MultiTouch())
  }

  const handleZoomInClick = () => {
    mapRef.current?.flyTo({ zoom: mapRef.current.getZoom() + 1 })
  }

  const handleZoomOutClick = () => {
    mapRef.current?.flyTo({ zoom: mapRef.current.getZoom() - 1 })
  }

  const handleMarkerClick = (coords) => () => {
    const flyTo = {
      zoom: 13.5,
      center: coords,
      essential: true,
    }

    if (isSameCoords(mapRef.current?.getCenter(), coords)) {
      flyTo.center = usCenter
      flyTo.zoom = initialZoom
    }

    mapRef.current?.flyTo(flyTo)
  }

  return (
    <Container data-testid="MapContainer">
      <MapGL
        ref={mapRef}
        initialViewState={initialViewState}
        mapboxAccessToken={ACCESS_TOKEN}
        mapStyle="mapbox://styles/click-therapeutics/ck7ev0xvj2onu1itd639jmdwy"
        scrollZoom={false}
        style={mapStyles()}
        onLoad={handleLoad}
      >
        <Marker
          latitude={props.locations.hq.coords[1]}
          longitude={props.locations.hq.coords[0]}
          anchor="bottom"
          style={markerStyles(theme, 40)}
          onClick={handleMarkerClick(props.locations.hq.coords)}
        >
          <LocationSvg style={{ maxWidth: `100%` }} />
        </Marker>
        <Marker
          latitude={props.locations.boston.coords[1]}
          longitude={props.locations.boston.coords[0]}
          anchor="bottom"
          style={markerStyles(theme)}
          onClick={handleMarkerClick(props.locations.boston.coords)}
        >
          <LocationSvg style={{ maxWidth: `100%` }} />
        </Marker>
        <Marker
          latitude={props.locations.miami.coords[1]}
          longitude={props.locations.miami.coords[0]}
          anchor="bottom"
          style={markerStyles(theme)}
          onClick={handleMarkerClick(props.locations.miami.coords)}
        >
          <LocationSvg style={{ maxWidth: `100%` }} />
        </Marker>
        <Marker
          latitude={props.locations.sf.coords[1]}
          longitude={props.locations.sf.coords[0]}
          anchor="bottom"
          style={markerStyles(theme)}
          onClick={handleMarkerClick(props.locations.sf.coords)}
        >
          <LocationSvg style={{ maxWidth: `100%` }} />
        </Marker>

        <ZoomControl>
          <button
            type="button"
            aria-label="Zoom in"
            title="Zoom in"
            onClick={handleZoomInClick}
          >
            <PlusSvg />
          </button>

          <button
            type="button"
            aria-label="Zoom out"
            title="Zoom out"
            onClick={handleZoomOutClick}
          >
            <MinusSvg />
          </button>
        </ZoomControl>
      </MapGL>
    </Container>
  )
}

const locationShape = PropTypes.shape({
  address: PropTypes.string,
  coords: isNumericTuple,
})

Map.propTypes = {
  locations: PropTypes.shape({
    hq: locationShape,
    boston: locationShape,
    miami: locationShape,
    sf: locationShape,
  }),
}

export default Map
