import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { gatsbyImageObject } from "../../config/sharp-image-proptypes"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Row from "../grid/row"
import Col from "../grid/column"
import Link from "../link"

import Spacer from "../spacer"
import Container from "../container"
import { GrayH2Heading } from "../common/heading"

const Section = styled.section`
  background: ${(props) => props.theme.white};
`

const LogoContainer = styled.div`
  width: 100%;
  max-width: ${rem(250)};
  margin: 0 auto;
  max-height: ${rem(160)};
  height: 100%;
  padding: ${rem(30)} 0;

  @media ${(props) => props.theme.smallUp} {
    &.logo-container::before {
      content: "";
      position: absolute;
      height: ${rem(160)};
      width: 1px;
      max-width: 1px;
      // For Windows Chromium-based browsers use border instead of width
      border-left: 1px solid ${(props) => props.theme.colorBlack};
      left: 0;
      top: 0;
      flex: 0 0 1px;
      display: block;
    }
  }
  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(16)};
    &.logo-container::before {
      content: "";
      position: absolute;
      height: 1px;
      max-height: 1px;
      width: 100%;
      background: ${(props) => props.theme.colorBlack};
      left: 0;
      top: ${rem(8)};
      display: block;
      flex: 0 0 1px;
    }
  }
  img {
    object-fit: contain !important;
  }
`

const StyledRow = styled(Row)`
  maxwidth: 1260px;
  height: auto;

  @media ${(props) => props.theme.smallDown} {
    flex-wrap: wrap;
  }
`

const StyledCol = styled(Col)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;
  justify-content: space-between;

  @media ${(props) => props.theme.smallDown} {
    flex: 1 1 100%;
  }
`
const Snippet = styled.div.attrs({ className: `styled-p` })`
  margin: ${rem(20)} auto;
  padding: 0 ${rem(30)};
  text-align: center;
  min-height: ${rem(90)};
  @media ${(props) => props.theme.smallDown} {
    min-height: unset;
  }
`

const ReadMoreButton = styled(Link)`
  margin: ${rem(20)} auto;
  text-transform: uppercase;
  position: relative;
  transition: background 0.3s, color 0.3s;
  padding: ${rem(16)} ${rem(30)};
  border: 1px solid;
  border-radius: 999em;
  display: inline-flex;
  letter-spacing: 0.175em;

  &:hover {
    background: ${(props) => props.theme.colorBlack};
    color: ${(props) => props.theme.colorWhite};
  }
`

const Collaborators = (props) => (
  <Section>
    <Spacer top bottom>
      <Container>
        <GrayH2Heading>{props.title}</GrayH2Heading>

        <Spacer top>
          <StyledRow>
            {props.collaborators.map((collaborator, index) => (
              <StyledCol key={index} smallDown={4} smallUp={4}>
                <LogoContainer
                  data-testid="LogoContainer"
                  className={index !== 0 ? `logo-container` : ``}
                  smallDown={4}
                  smallUp={4}
                >
                  <GatsbyImage
                    image={getImage(collaborator.logo)}
                    alt={collaborator.collaborator}
                  />
                </LogoContainer>
                <Snippet
                  className="styled-p"
                  dangerouslySetInnerHTML={{ __html: collaborator.snippet }}
                />
                <ReadMoreButton to={collaborator.link}>
                  {collaborator.buttonText}
                </ReadMoreButton>
              </StyledCol>
            ))}
          </StyledRow>
        </Spacer>
      </Container>
    </Spacer>
  </Section>
)

Collaborators.propTypes = {
  title: PropTypes.string,
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      logo: gatsbyImageObject.isRequired,
      snippet: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Collaborators
