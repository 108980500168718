import React from "react"

const Logo = (props) => (
  <svg
    width="283"
    height="333"
    viewBox="0 0 283 333"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M99.8304 81.4018V108.025H117.722C123.492 108.025 128.16 112.687 128.16 118.448V254.545C99.0633 248.401 77.153 222.561 77.153 191.691V108.042V90.859C77.153 55.4445 106.017 26.6233 141.5 26.6233C165.828 26.6233 187.038 40.1764 197.96 60.1065H227.14C214.518 25.0582 180.952 0 141.5 0C94.4445 0 55.7263 35.6643 50.9907 81.4018H0V108.025H50.4905V191.691C50.4905 237.362 84.223 275.141 128.16 281.585V333H154.823V281.601V254.562V118.465C154.823 112.704 159.492 108.042 165.261 108.042H282.983V81.4018H99.8304Z"
      fill="white"
    />
    <path
      d="M176.167 245.788V275.742C209.232 262.122 232.51 229.638 232.51 191.709H205.847C205.847 214.386 193.992 234.35 176.167 245.788Z"
      fill="white"
    />
  </svg>
)

export default Logo
