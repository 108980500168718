import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import FeedbackSection from "../components/feedback-section"

import { StoreContext } from "../store"

import WhatWeDoIcon1 from "../assets/images/temp/what-we-do-icon-1.svg"
import WhatWeDoIcon2 from "../assets/images/temp/what-we-do-icon-2.svg"
import WhatWeDoIcon3 from "../assets/images/temp/what-we-do-icon-3.svg"
import WhatWeDoIcon4 from "../assets/images/temp/what-we-do-icon-4.svg"

import Meta from "../components/meta"
import Hero from "../components/page-start/hero"
import FeaturedNews from "../components/page-start/featured-news"
import OurMission from "../components/page-start/our-mission"
import WhatWeDo from "../components/page-start/what-we-do"
import Leadership from "../components/page-start/leadership"
import Collaborators from "../components/page-start/collaborators"
import News from "../components/page-start/news"
import ContactUs from "../components/page-start/contact-us"

import { personFromTeamMember } from "../utils/team-member"
import { contentFromRelease, sortByReleaseDate } from "../utils/press-release"
import { collaboratorsFromData } from "../utils/collaborators"
import ScienceOfChange from "../components/page-start/science-of-change"

class HomePageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const leadership = this.props.data.leadership.nodes

    const people = leadership.length
      ? leadership[0].teamMembers.map(personFromTeamMember)
      : []

    const press = this.props.data.press.nodes

    const featured = this.props.data.featured.nodes

    const collaborators = this.props.data.collaborators.nodes

    const content = {
      meta: {
        title: `Click Therapeutics | Developing Software As Treatments`,
        titleOverridePattern: true,
        description: `A biotechnology company that develops, validates, and commercializes software as prescription medical treatments for people with unmet medical needs. Learn more.`,
        keywords: `ClickTx, Digital Therapeutics, DTx, Neurobehavioral, Pharmacotherapies, digital medicine`,
      },
      featuredNews: {
        label: featured.length ? featured[0].title : `Recent News:`,
        content: featured.length
          ? featured[0].pressReleases.map(contentFromRelease)
          : [],
      },
      scienceOfChange: {
        title: `Science of Change`,
        body: `We are committed to helping you live the life you want. That is why we are developing the science of change. Our digital therapeutics empower you to alter the underlying mechanisms of disease&mdash;to target the thoughts, sensations, emotions and behaviors that define the experience of your condition&mdash;to help you achieve a healthier, happier life.`,
      },
      feedbackSection1: {
        image: this.props.data.feedbackSectionImage1,
        text: `“l did not know if I could stop smoking but I had been thinking
        about trying to stop for a while now. With your help and support, we
        did it.”`,
        author: `- CT-101 Clickotine Patient`,
        button: {
          url: `/digital-therapeutics`,
          text: `Learn more about Digital Therapeutics`,
        },
      },
      whatwedoBlock: {
        title: `Developing Software <br/>As Treatments`,
        description: `We are a regulated Software as a Medical Device (SaMD)-focused biotechnology company that develops our applications as medical treatments in accordance with all relevant FDA, ISO and IEC standards.`,
        blobs: [
          {
            icon: WhatWeDoIcon1,
            title: `Develop`,
            content: `We develop prescription software applications as medical devices that work independently or in conjunction with pharmacotherapies to treat disease.`,
          },
          {
            icon: WhatWeDoIcon2,
            title: `Validate`,
            content: `We clinically validate these applications through rigorous randomized, controlled trials to demonstrate safety and efficacy.`,
          },
          {
            icon: WhatWeDoIcon3,
            title: `Register`,
            content: `We seek FDA clearance for our apps as class II medical devices with independent therapeutic treatment claims or as adjuncts to existing therapy.`,
          },
          {
            icon: WhatWeDoIcon4,
            title: `Commercialize`,
            content: `Physicians prescribe our apps and payers reimburse for them, while we generate and apply real world evidence to continuously personalize and improve the applications.`,
          },
        ],
      },
      leadershipBlock: {
        title: `Leadership`,
        people,
      },
      collaboratorsBlock: collaboratorsFromData(collaborators),
      feedbackSection2: {
        image: this.props.data.feedbackSectionImage2,
        text: `“I think that this has been one of my best experiences using an interactive app. I can't thank all of you enough for helping me on this journey.”`,
        author: `- CT-101 Clickotine Patient`,
        button: {
          url: `/for-patients`,
          text: `Learn more about how we help our patients`,
        },
      },
      newsBlock: {
        title: `In The News`,
        news: press.map(contentFromRelease).sort(sortByReleaseDate),
      },
      contactUsBlock: {
        title: `Contact us`,
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero image={this.props.data.heroImage} />

        <FeaturedNews {...content.featuredNews} />

        <OurMission />

        <ScienceOfChange {...content.scienceOfChange} />

        <FeedbackSection {...content.feedbackSection1} />

        <Collaborators {...content.collaboratorsBlock} />

        <WhatWeDo {...content.whatwedoBlock} />

        <Leadership {...content.leadershipBlock} />

        <News {...content.newsBlock} />

        <FeedbackSection {...content.feedbackSection2} />

        <ContactUs {...content.contactUsBlock} />
      </React.Fragment>
    )
  }
}

HomePageContent.propTypes = {
  data: PropTypes.object,
}

const HomePage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/home-hero.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }

        feedbackSectionImage1: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/customer-feedback-bg.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }

        feedbackSectionImage2: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/start-page-image-section.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }

        collaborators: allContentfulCollaborators(
          filter: { title: { eq: "Our Collaborators" } }
        ) {
          distinct(field: id)
          nodes {
            id
            title
            collaborators {
              buttonText
              collaborator
              link
              logo {
                gatsbyImageData(layout: CONSTRAINED, width: 500)
              }
              snippet {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        press: allContentfulPressRelease(
          filter: { node_locale: { eq: "en-US" } }
          limit: 28
        ) {
          nodes {
            title
            slug
            releaseDate(formatString: "YYYY-MM-DD")
          }
        }

        featured: allContentfulFeaturedPress(
          filter: { node_locale: { eq: "en-US" } }
        ) {
          nodes {
            title
            pressReleases {
              title
              slug
              releaseDate(formatString: "YYYY-MM-DD")
            }
          }
        }

        leadership: allContentfulDepartment(
          filter: {
            departmentName: { eq: "Leadership" }
            node_locale: { eq: "en-US" }
          }
        ) {
          nodes {
            departmentName
            teamMembers {
              credentialsSuffix
              lastName
              firstName
              description {
                description
              }
              qualifications
              linkedInUrl
              workTitle
              profileImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 242
                  placeholder: BLURRED
                  backgroundColor: "#000000"
                )
              }
            }
          }
        }
      }
    `}
    render={(data) => <HomePageContent data={data} {...props} />}
    // render={(data) => {
    //   console.log(data)
    //   return null
    // }}
  />
)

export default HomePage
