import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"
import Container from "../container"
import Spacer from "../spacer"
import Link from "../link"
import Map from "../map/map-box"
import { locations } from "./locations"
import { GrayH2Heading } from "../common/heading"

const Wrapper = styled.section`
  background: ${(props) => props.theme.colorSand};

  @media ${(props) => props.theme.smallDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const StyledCol = styled(Col)`
  @media ${(props) => props.theme.smallUp} {
    padding-bottom: ${rem(40)};
  }
`

const StyledRow = styled(Row)`
  position: relative;

  @media ${(props) => props.theme.smallUp} {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 1px;
      background: ${(props) => props.theme.colorBlack};
    }
  }

  @media ${(props) => props.theme.smallDown} {
    display: flex;
    flex-wrap: wrap;

    ${StyledCol} {
      order: 2;
    }
  }
`

const ContactsLabel = styled.span.attrs({ className: `styled-label` })`
  display: block;

  @media ${(props) => props.theme.smallUp} {
    margin-bottom: ${rem(26)};
  }

  @media ${(props) => props.theme.smallDown} {
    margin-bottom: ${rem(10)};
  }
`

const MapHeading = styled.h4.attrs({ className: `styled-h4` })`
  @media ${(props) => props.theme.smallUp} {
    margin-bottom: ${rem(40)};
  }

  @media ${(props) => props.theme.smallDown} {
    margin-bottom: ${rem(30)};
  }

  b {
    font-weight: bold;
  }
`

const AddressList = styled.ul`
  list-style: disc;
  margin-left: ${rem(20)};
`
const MapAddress = styled.li.attrs({ className: `styled-h4` })`
  font-size: ${rem(20)};
  margin-bottom: ${rem(20)};

  @media ${(props) => props.theme.smallDown} {
    font-size: ${rem(16)};
  }

  b {
    font-weight: bold;
  }
`

const EmailLink = styled(Link)`
  @media ${(props) => props.theme.xlargeUp} {
    position: relative;
    overflow: hidden;
    font-size: ${rem(20)};

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      margin-top: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      opacity: 0.4;
      transition: transform 0.5s, transform-origin 0s 0s;
      transform: scaleX(0);
      transform-origin: right;
    }

    &:hover,
    &.active {
      &::after {
        transform-origin: left;
        transform: scaleX(1);
        transition: transform 0.5s 0s, transform-origin 0s 0s;
      }
    }
  }
  @media ${(props) => props.theme.smallDown} {
    font-size: ${rem(16)};
  }
`

const ContactUs = (props) => (
  <Wrapper id="contact_us">
    <Container>
      <Spacer top bottom>
        <GrayH2Heading>{props.title}</GrayH2Heading>
      </Spacer>

      <StyledRow>
        <StyledCol smallUp={5}>
          <Map locations={locations} />
        </StyledCol>

        <Col smallUp={5} smallUpOffset={2}>
          <ContactsLabel>Email us</ContactsLabel>
          <MapHeading>
            <EmailLink target="_blank" to="mailto:info@clicktherapeutics.com">
              info@clicktherapeutics.com
            </EmailLink>
          </MapHeading>

          <ContactsLabel>Our offices</ContactsLabel>
          <AddressList>
            <MapAddress>
              <address
                dangerouslySetInnerHTML={{ __html: locations.hq.address }}
              />
            </MapAddress>
            <MapAddress>
              <address
                dangerouslySetInnerHTML={{ __html: locations.boston.address }}
              />
            </MapAddress>
            <MapAddress>
              <address
                dangerouslySetInnerHTML={{ __html: locations.miami.address }}
              />
            </MapAddress>
            <MapAddress>
              <address
                dangerouslySetInnerHTML={{ __html: locations.sf.address }}
              />
            </MapAddress>
          </AddressList>
        </Col>
      </StyledRow>

      <Spacer bottom />
    </Container>
  </Wrapper>
)

ContactUs.propTypes = {
  title: PropTypes.string,
}

export default ContactUs
