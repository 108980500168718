import React from "react"

/* c8 ignore next 12 */
const Plus = (props) => (
  <svg
    height="448pt"
    viewBox="0 0 448 448"
    width="448pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M272 184c-4.418 0-8-3.582-8-8v-176h-80v176c0 4.418-3.582 8-8 8h-176v80h176c4.418 0 8 3.582 8 8v176h80v-176c0-4.418 3.582-8 8-8h176v-80zm0 0" />
  </svg>
)

export default Plus
