import React from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import PropTypes from "prop-types"
import { rem } from "polished"

import Container from "../container"
import Spacer from "../spacer"
import Row from "../grid/row"
import Col from "../grid/column"
import { GrayH2Heading } from "../common/heading"

const Wrapper = styled.section`
  background: ${(props) => props.theme.colorSand};
`

const VideoContainer = styled.div`
  background-color: ${(props) => props.theme.colorSand};
  margin: 0 auto;
  max-width: ${rem(960)};
  @media ${(props) => props.theme.smallDown} {
    padding-left: ${rem(15)};
    padding-right: ${rem(15)};
  }
`
const StyledRow = styled(Row)`
  @media ${(props) => props.theme.smallDown} {
    padding-left: ${rem(5)};
    padding-right: ${rem(5)};
  }
`

const ScienceOfChange = (props) => {
  return (
    <Wrapper id="science_of_change" data-testid="science-of-change">
      <Container>
        <Spacer top>
          <GrayH2Heading>{props.title}</GrayH2Heading>
        </Spacer>
        <Spacer top bottom>
          <StyledRow>
            <Col>
              <p
                className="styled-h4"
                dangerouslySetInnerHTML={{ __html: props.body }}
              />
            </Col>
          </StyledRow>
        </Spacer>
        <Spacer bottom>
          <VideoContainer>
            <ReactPlayer
              url="https://player.vimeo.com/video/665715061?h=2dcdbe3e04&player_id=0&app_id=58479"
              width="100%"
              height="100%"
              muted={false}
              playing={false}
              loop={false}
              controls={true}
              config={{
                vimeo: {
                  playerOptions: {
                    autopause: false,
                    background: false,
                    badge: false,
                    byline: false,
                    player_id: 0,
                    portrait: false,
                    responsive: true,
                    speed: false,
                    title: false,
                  },
                },
              }}
            />
          </VideoContainer>
        </Spacer>
      </Container>
    </Wrapper>
  )
}

ScienceOfChange.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default ScienceOfChange
