import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import CoverVisual from "./cover-visual"
import Container from "./container"
import Button from "./button"

import Row from "./grid/row"
import Col from "./grid/column"

const ContainerEl = styled.section`
  position: relative;
  text-align: center;
  color: ${(props) => props.theme.colorWhite};

  @media ${(props) => props.theme.smallUp} {
    padding-top: ${rem(215)};
    padding-bottom: ${rem(170)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  ${Container} {
    z-index: 2;
    position: relative;
  }
`

const QuoteAuthor = styled.h5.attrs({ className: `styled-label` })`
  margin-top: ${rem(44)};
`

const ButtonCol = styled(Col)`
  display: flex;
`

const ButtonWrapper = styled.div`
  align-self: center;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(40)};
  }
`

const FeedbackSection = (props) => (
  <ContainerEl>
    <Container>
      <Row>
        <Col smallUp={6}>
          <div className="styled-quote">{props.text}</div>

          <QuoteAuthor>{props.author}</QuoteAuthor>
        </Col>

        <ButtonCol smallUp={4} smallUpOffset={2}>
          <ButtonWrapper>
            <Button to={props.button.url}>{props.button.text}</Button>
          </ButtonWrapper>
        </ButtonCol>
      </Row>
    </Container>

    <CoverVisual image={props.image} alt="Customer Feedback" />
  </ContainerEl>
)

FeedbackSection.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
  author: PropTypes.string,
  button: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
}

export default FeedbackSection
