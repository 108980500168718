import React from "react"
import ReactPlayer from "react-player"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"
import { useMediaQuery } from "react-responsive"
import themeMQ from "../../theme/sections/mq"
import { gatsbyImageObject } from "../../config/sharp-image-proptypes"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Shadow from "../hero-shadow"

import ContentSlider from "./content-slider"

// import LogoIcon from "../svg/logo-icon"
import Logo from "../svg/logo"
import Arrow from "../svg/hero-down-arrow"

const StyledArrow = styled((props) => <Arrow {...props} />)`
  width: ${rem(10)};
  animation: bounce 2s 2;
`

/* c8 ignore next 16 */
const LogoSlide = styled.div`
  svg {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media ${(props) => props.theme.smallUp} {
      width: ${rem(600)};
    }

    @media ${(props) => props.theme.smallDown} {
      width: ${rem(300)};
    }
  }
`

const Container = styled.div`
  // height: 100vh;
  position: relative;
  overflow: hidden;

  ${(props) =>
    /* c8 ignore next 7 */
    props.isMobile
      ? css`
          height: ${window?.innerHeight ?? 640}px;
        `
      : `height: 100vh;`}

  ${Shadow} {
    z-index: 3;
  }
`

const Video = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colorSand};

  iframe {
    width: 177.77777778vh !important;
    height: 56.25vw !important;
    min-width: 100% !important;
    min-height: 100% !important;
    box-sizing: border-box !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1;

    ${
      /* c8 ignore next 7 */
      (props) =>
        props.orientation === `portrait` &&
        css`
          width: 56.25vh !important;
          height: 177.77777778vw !important;
        `
    }
  }
`

const PlaceholderImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`
/* c8 ignore next 22 */
const ArrowDownContainer = styled(motion.button)`
  position: absolute;
  bottom: ${rem(30)};
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: ${(props) => props.theme.colorWhite};
  z-index: 3;
  user-select: none;
  overflow: visible;

  span {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.175em;
    text-transform: uppercase;
    display: block;
    margin-top: ${rem(10)};
  }
`

const Hero = (props) => {
  const mqMediumDown = useMediaQuery({ query: themeMQ.mediumDown })

  return (
    <React.Fragment>
      <Container isMobile={typeof window != `undefined` && mqMediumDown}>
        <Shadow />

        <ContentSlider loops={false}>
          <h1 className="styled-h2">Software as treatments</h1>

          <h1 className="styled-h2">
            Delivered directly to your phone, prescribed by your physician
          </h1>

          <LogoSlide>
            <Logo />
          </LogoSlide>

          {/* <LogoIconSlide>
            <LogoIcon />
          </LogoIconSlide> */}
        </ContentSlider>

        <Video>
          <ReactPlayer
            url="https://vimeo.com/395260791"
            width="100%"
            height="100%"
            volume={0}
            muted={true}
            playing={true}
            loop={true}
            playsinline
            config={{
              vimeo: {
                playerOptions: {
                  background: 1,
                },
              },
            }}
          />
        </Video>

        <PlaceholderImg image={getImage(props.image)} alt="" />

        <ArrowDownContainer
          data-testid="ArrowDownContainer"
          onClick={() => {
            if (typeof window !== `undefined`) {
              window.scrollTo({
                top: window.innerHeight,
                behavior: `smooth`,
              })
            }
          }}
          initial={{
            opacity: 0,
            y: 10,
            x: `-50%`,
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              delay: 2,
            },
          }}
        >
          <StyledArrow />
          <span>discover more</span>
        </ArrowDownContainer>
      </Container>
    </React.Fragment>
  )
}

Hero.propTypes = {
  image: gatsbyImageObject,
}

export default Hero
