import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { useInterval } from "react-use"

import Container from "../container"

const MotionContainer = styled(motion.div)`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
  color: ${(props) => props.theme.colorWhite};
  user-select: none;

  div {
    width: 100%;
    text-align: center;
    align-self: center;
  }
`

const TransitionTime = 0.75
const SlideTime = 5000

const ContentSlider = (props) => {
  const { loop, children } = props
  const [currentSlide, setCurrentSlide] = React.useState(0)
  // If delay has a value, then loop will continue, so for no loop, last element gets no delay
  const delay = loop || currentSlide < children.length - 1 ? SlideTime : null

  useInterval(() => {
    setCurrentSlide((prevSlide) =>
      loop
        ? (prevSlide + 1) % children.length
        : Math.min(prevSlide + 1, children.length - 1)
    )
  }, delay)

  return (
    <AnimatePresence>
      {children.map(
        (item, index) =>
          currentSlide === index && (
            <MotionContainer
              key={index}
              initial={{
                opacity: 0,
                filter: `blur(10px)`,
                transition: {
                  duration: TransitionTime,
                  delay: TransitionTime,
                },
              }}
              animate={{
                opacity: 1,
                filter: `blur(0px)`,
                transition: {
                  duration: TransitionTime,
                  delay: TransitionTime,
                },
              }}
              exit={{
                opacity: 0,
                filter: `blur(10px)`,
                transition: { duration: TransitionTime },
              }}
            >
              <Container>{item}</Container>
            </MotionContainer>
          )
      )}
    </AnimatePresence>
  )
}

export default ContentSlider

ContentSlider.propTypes = {
  children: PropTypes.array,
  loop: PropTypes.bool,
}
