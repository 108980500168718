import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"

import Button from "../button"
import Link from "../link"

import TeamMember from "../page-about/team/team-member"

import Spacer from "../spacer"
import Row from "../grid/row"
import Col from "../grid/column"
import { GrayH2Heading } from "../common/heading"

const Section = styled.section`
  background: #f2f2f2;
`

const Bottom = styled.div`
  width: ${rem(930)};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const PersonWrapper = styled.div`
  @media ${(props) => props.theme.smallUp} {
    padding: 0 ${rem(30)} ${rem(40)} ${rem(30)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding: 0 ${rem(10)} ${rem(20)} ${rem(10)};
  }
`

const ButtonRow = styled.div`
  color: ${(props) => props.theme.colorBlack};
  text-align: center;
  padding-top: ${rem(60)};
`

const Leadership = (props) => (
  <Section>
    <Container>
      <Spacer top bottom>
        <GrayH2Heading>{props.title}</GrayH2Heading>
      </Spacer>

      <Bottom>
        <Row>
          {props.people.map((item, index) => (
            <Col key={index} smallDown={6} smallUp={4}>
              <PersonWrapper>
                <TeamMember hasModal {...item} />
              </PersonWrapper>
            </Col>
          ))}
        </Row>
        <Spacer bottom>
          <ButtonRow>
            <Link to={`/team`}>
              <Button>view our team</Button>
            </Link>
          </ButtonRow>
        </Spacer>
      </Bottom>
    </Container>
  </Section>
)

Leadership.propTypes = {
  title: PropTypes.string,
  people: PropTypes.array.isRequired,
}

export default Leadership
