import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Container from "../container"

import Spacer from "../spacer"
import Row from "../grid/row"
import Col from "../grid/column"
import { GrayH2Heading } from "../common/heading"

const Text1 = styled.p.attrs({ className: `styled-cue` })`
  width: ${rem(403)};
  max-width: 100%;

  @media ${(props) => props.theme.smallDown} {
    padding-bottom: ${rem(20)};
  }
`

const StyledRow = styled(Row)`
  @media ${(props) => props.theme.smallDown} {
    padding-left: ${rem(5)};
    padding-right: ${rem(5)};
  }
`

const OurMission = () => (
  <section>
    <Container>
      <Spacer top>
        <GrayH2Heading>Our Mission</GrayH2Heading>
      </Spacer>
      <Spacer top bottom>
        <StyledRow>
          <Col smallUp={6}>
            <Text1>
              Deliver safe and effective digital treatments to patients in need
            </Text1>
          </Col>
          <Col smallUp={6}>
            <p className="styled-h4">
              We develop, validate, and commercialize software as prescription
              medical treatments for people with unmet medical needs. Through
              cognitive and neurobehavioral mechanisms, Click’s Digital
              Therapeutics™ enable change within individuals, and are designed
              to be used independently or in conjunction with biomedical
              treatments.
            </p>
          </Col>
        </StyledRow>
      </Spacer>
    </Container>
  </section>
)

export default OurMission
