export const locations = {
  hq: {
    address: `<b>Headquarters</b><br/>80 White Street<br />3rd Floor<br />New York, NY 10013`,
    coords: [-74.0022552, 40.7177093],
  },
  boston: {
    address: `855 Boylston Street<br />10th Floor<br />Boston, MA 02116`,
    coords: [-71.083138, 42.348831],
  },
  miami: {
    address: `1111 Brickell Avenue<br />10th Floor<br />Miami, FL 33131`,
    coords: [-80.1908314, 25.762473],
  },
  sf: {
    address: `1700 Montgomery Street<br />Suite 108<br />San Francisco, CA 94111`,
    coords: [-122.4050816, 37.8053281],
  },
}
