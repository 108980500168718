import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"
import Row from "../grid/row"
import Col from "../grid/column"

import Link from "../link"

const NewsContainer = styled.div`
  @media ${(props) => props.theme.largeUp} {
    padding: ${rem(70)} 0;
  }

  @media ${(props) => props.theme.largeDown} {
    padding: ${rem(30)} 0;
  }
`

const El = styled.div`
  background: #333;
  padding: ${rem(20)} 0;
  color: ${(props) => props.theme.colorWhite};
`

const NewsEl = styled.div`
  a {
    text-decoration: underline;
  }
`

const NewsTitle = styled.span.attrs({ className: `styled-p` })`
  color: ${(props) => props.theme.colorSand};

  @media ${(props) => props.theme.smallUp} {
    margin-left: ${rem(13)};
    margin-right: ${rem(5)};
    display: inline-block;
  }

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(10)};
    margin-bottom: ${rem(10)};
    display: block;
  }
`

const News = (props) => (
  <NewsEl>
    <span className="styled-label">recent news:</span>
    <NewsTitle>{props.title} </NewsTitle>
    <Link to={props.url}>Read more</Link>
  </NewsEl>
)

const FeaturedNews = (props) => (
  <El>
    <Container>
      <Row>
        <Col smallUp={12}>
          {props.content.length > 0 && (
            <NewsContainer>
              {props.content.map((item, index) => (
                <News key={index} {...item} />
              ))}
            </NewsContainer>
          )}
        </Col>
      </Row>
    </Container>
  </El>
)

News.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
}

FeaturedNews.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
}

export default FeaturedNews
