import React from "react"

/* c8 ignore next 12 */
const Minus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 298.667 298.667"
    enableBackground="new 0 0 298.667 298.667"
    {...props}
  >
    <path d="M0 128h298.667v42.667h-298.667z" />
  </svg>
)

export default Minus
