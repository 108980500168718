import React from "react"

/* c8 ignore next 13 */
const Location = (props) => (
  <svg
    viewBox="-119 -21 682 682.66669"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M216.211 0c-122.664 0-222.461 99.797-222.461 222.461 0 154.176 222.68 417.539 222.68 417.539s222.242-270.945 222.242-417.539c0-122.664-99.793-222.461-222.461-222.461zm67.121 287.598c-18.508 18.504-42.813 27.758-67.121 27.758-24.305 0-48.617-9.254-67.117-27.758-37.012-37.008-37.012-97.227 0-134.238 17.922-17.93 41.762-27.805 67.117-27.805 25.355 0 49.191 9.879 67.121 27.805 37.012 37.012 37.012 97.23 0 134.238zm0 0" />
  </svg>
)

export default Location
