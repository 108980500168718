import { getMarkdownRemarkContent } from "./markdown-remark-content"

const defaultCollaborator = {
  buttonText: `Read More`,
  link: `/`,
  logo: {
    gatsbyImageData: {},
  },
  snippet: {
    childMarkdownRemark: {
      html: ``,
    },
  },
}
function collaboratorFromData(datum = defaultCollaborator) {
  if (datum === null) {
    return {
      ...defaultCollaborator,
      logo: defaultCollaborator.logo,
      snippet: defaultCollaborator.snippet.childMarkdownRemark.html,
    }
  }
  return {
    ...datum,
    logo: datum.logo,
    snippet: getMarkdownRemarkContent(datum.snippet),
  }
}

const defaultNode = { title: ``, collaborators: [] }

export function collaboratorsFromData(nodes = [defaultNode]) {
  if (nodes === null || nodes.length === 0) {
    return collaboratorsFromNode(defaultNode)
  }
  return collaboratorsFromNode(nodes[0])
}

function collaboratorsFromNode(node) {
  return {
    title: node.title,
    collaborators: node.collaborators.map(collaboratorFromData),
  }
}
