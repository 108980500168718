import React from "react"

const HeroDownArrow = (props) => (
  <svg
    width="10"
    height="32"
    viewBox="0 0 10 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.55538 31.4446C4.80094 31.6902 5.19906 31.6902 5.44462 31.4446L9.4462 27.443C9.69176 27.1975 9.69176 26.7994 9.4462 26.5538C9.20065 26.3082 8.80252 26.3082 8.55696 26.5538L5 30.1108L1.44304 26.5538C1.19748 26.3082 0.799355 26.3082 0.553798 26.5538C0.308241 26.7994 0.308241 27.1975 0.553798 27.443L4.55538 31.4446ZM5.62879 1L5.62879 0.371212L4.37121 0.371212L4.37121 1L5.62879 1ZM5.62879 31L5.62879 1L4.37121 1L4.37121 31L5.62879 31Z"
      fill="white"
    />
  </svg>
)

export default HeroDownArrow
