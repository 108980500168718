import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { useMediaQuery } from "react-responsive"

import Container from "../container"
import Spacer from "../spacer"
import Link from "../link"
import Carousel from "../carousel"

import themeMQ from "../../theme/sections/mq"

import SliderArrowLeft from "../../assets/images/slider-arrow-left.svg"
import SliderArrowRight from "../../assets/images/slider-arrow-right.svg"
import { CenteredH2Heading } from "../common/heading"

const ArrowLeftEl = styled.div`
  background: url(${SliderArrowLeft});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(50)};
    height: ${rem(50)};
  }
`

const ArrowRightEl = styled.div`
  background: url(${SliderArrowRight});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(50)};
    height: ${rem(50)};
  }
`

// new carousel

const El = styled.section`
  background: ${(props) => props.theme.colorTangerine};
  color: ${(props) => props.theme.colorWhite};
  overflow: hidden;
`

const NewsItemTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const NewsTitle = styled.h5`
  margin-top: ${rem(20)};
`

const NewsButton = styled(Link)`
  margin-top: ${rem(20)};
  padding: ${rem(8)} ${rem(15)};
  border: 1px solid;
  border-radius: 999em;
  display: inline-block;
  vertical-align: top;
  font-size: ${rem(13)};
  text-transform: uppercase;
  letter-spacing: 0.175em;
  position: relative;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${(props) => props.theme.colorWhite};
    color: ${(props) => props.theme.colorTangerine};
  }
`

const NewsEl = styled.div.attrs({ className: `styled-p` })`
  padding-right: ${rem(22)};
  padding-left: ${rem(22)};
`

const SliderContainer = styled.div`
  position: relative;

  .carousel-button-back,
  .carousel-button-next {
    top: 50%;
    position: absolute;
    transition: transform 0.3s, opacity 0.3s;

    @media ${(props) => props.theme.smallUp} {
      margin-top: ${rem(-40)};
    }

    @media ${(props) => props.theme.smallDown} {
      margin-top: ${rem(-20)};
    }
  }

  .carousel-button-back {
    left: 0;

    &:disabled {
      transform: translateX(-10px);
      opacity: 0;
    }
  }

  .carousel-button-next {
    right: 0;

    &:disabled {
      transform: translateX(10px);
      opacity: 0;
    }
  }

  .carousel {
    width: ${rem(1068)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${(props) => props.theme.smallUp} and ${(props) =>
        props.theme.xxlargeDown} {
      max-width: 80%;
    }

    @media ${(props) => props.theme.smallDown} {
      width: ${rem(300)};
      max-width: 100%;
    }
  }
`

const NewsItem = (props) => (
  <NewsEl>
    <NewsItemTop>
      <span>{props.category}</span>
      <span>{props.date}</span>
    </NewsItemTop>

    <NewsTitle>{props.title}</NewsTitle>

    <NewsButton to={props.url}>read more</NewsButton>
  </NewsEl>
)

const News = (props) => {
  const [tick, setTick] = useState(0)
  const mqMediumDown = useMediaQuery({ query: themeMQ.mediumDown })

  useEffect(() => {
    if (mqMediumDown) {
      setTick((prevTick) => prevTick + 1)
    }
  }, [])

  const slides = props.news.map((item, index) => (
    <NewsItem key={index} {...item} />
  ))

  return (
    <El>
      <Container>
        <Spacer top bottom>
          <CenteredH2Heading>{props.title}</CenteredH2Heading>
        </Spacer>
      </Container>

      <Spacer bottom>
        <SliderContainer>
          <Carousel
            key={tick}
            /* c8 ignore next */
            visibleSlides={mqMediumDown ? 1 : 3}
            slides={slides}
          >
            <ArrowLeftEl />
            <ArrowRightEl />
          </Carousel>
        </SliderContainer>
      </Spacer>
    </El>
  )
}

News.propTypes = {
  title: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
}

NewsItem.propTypes = {
  category: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default News
